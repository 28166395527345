import { collection, deleteDoc, doc, query, setDoc } from 'firebase/firestore'
import { FireBaseStore } from '@/model/firebase/google-fire-base'
import { resultOfId } from '@/model/firebase/queryUtils'
import { getCurrentDeviceId } from '@/model/dataLayer/service/RestaurantInfoService'

const storeTakeawayVoucher = 'storeTakeawayVoucher'

export async function addTakeawayVoucher (voucher) {
  const deviceId = await getCurrentDeviceId()
  console.log(voucher, deviceId)
  voucher.code = voucher.code.toUpperCase()
  const purchaseRef = doc(FireBaseStore, storeTakeawayVoucher,
    deviceId, 'list',
    voucher.code)
  await setDoc(purchaseRef, { ...voucher })
}

export async function deleteTakeawayVoucher (code) {
  const deviceId = await getCurrentDeviceId()
  await deleteDoc(doc(FireBaseStore, storeTakeawayVoucher,
    deviceId, 'list', code))
}

export async function getTakeawayVoucher () {
  const deviceId = await getCurrentDeviceId()
  const collectionRef = collection(FireBaseStore, storeTakeawayVoucher, deviceId, 'list')
  const res = query(collectionRef)
  return await resultOfId(res)
}
