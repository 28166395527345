<template>
  <div>
    <ik-data-table
      ref="table"
      :model="model"
      :uuse-custom-action="true"
      entity-name="PickupVoucher"
      icon="mdi-label"
    >
      <template #item.maxCount="{item}">
        <div>
          {{ item.currentCount }}/{{ item.maxCount }}
        </div>
      </template>
      <template #footer="">
        <v-btn
          color="blue lighten-4 black--text"
          elevation="0"
          @click="uploadTerminal"
        >
          <div
            class="d-flex align-center"
          >
            <v-icon
              left
            >
              mdi-cloud-upload-outline
            </v-icon>
            <template>
              <div
                class="text-truncate"
                style="max-width: 80px !important;"
              >
                {{ $t('Upload') }}
              </div>
            </template>
          </div>
        </v-btn>
      </template>
    </ik-data-table>
  </div>
</template>

<script>

import i18n from '@/i18n'
import TakeawayVoucher from '@/model/dataLayer/api/takeaway/TakeawayVoucher'
import IkDataTable from '@/plugins/EntityFrameWork/IkDataTable'
import { syncData } from '@/model/dataLayer/api/takeaway/TakeawayManager'

export default {
  name: 'TakeawayVoucher',
  components: {
    IkDataTable
  },
  data () {
    return {
      model: TakeawayVoucher,
      editItem: [],
      trueOrFalse: [
        {
          id: '0',
          name: i18n.t('no')
        },
        {
          id: '1',
          name: i18n.t('yes')
        }
      ]
    }
  },
  methods: {
    async uploadTerminal () {
      try {
        await syncData()
      } catch (e) {
        console.log(e, 'error')
      }
    }
  }
}
</script>

<style scoped>

</style>
