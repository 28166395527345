import { addTakeawayVoucher, deleteTakeawayVoucher, getTakeawayVoucher } from '@/model/firebase/takeawayVoucher'
import IKDataEntity from '@/plugins/EntityFrameWork/IKDataEntity'
import dayjs from 'dayjs'

const entity = {
  code: {
    displayName: 'VoucherRedemptionCode'
  },
  discountStr: {
    displayName: 'VoucherDiscount',
    formConfig: {
      hint: 'VoucherDiscountHint'
    }
  },
  maxPrice: {
    formConfig: {
      hint: 'VoucherMaxDiscount',
      required: false
    },
    displayName: 'MaxDiscountAmount'
  },
  minPrice: {
    formConfig: {
      hint: 'MinDiscountAmount',
      required: false
    },
    displayName: 'EffectiveMinimumAmount'
  },
  maxCount: {
    tableConfig: {
      overwrite: true
    },

    formConfig: {
      hint: 'MaxNumberOfTimesCouponCanUsed',
      required: false
    },
    form: false,
    displayName: 'TotalTimes'
  },
  editMaxCount: {
    tableConfig: {
      overwrite: true
    },

    formConfig: {
      hint: 'MaxNumberOfTimesCouponCanUsed',
      required: false
    },
    header: false,
    displayName: 'TotalTimes'
  },
  currentCount: {
    formConfig: {
      hint: 'NumbersLeftOfTimesCouponCanUsed'
    },
    displayName: 'VoucherUsedAmountLeft',
    form: false,
    header: false
  },
  activeFrom: {
    type: IKDataEntity.Types.Date,
    form: false,
    displayName: 'VoucherStartRange'
  },
  activeTo: {
    type: IKDataEntity.Types.Date,
    form: false,
    displayName: 'VoucherEndRange'
  },
  activeFromDate: {
    type: IKDataEntity.Types.Date,
    header: false,
    displayName: 'VoucherStartRange',
    formConfig: {
      required: false
    }
  },
  activeFromTime: {
    type: IKDataEntity.Types.Time,
    header: false,
    displayName: 'VoucherStartTime',
    formConfig: {
      required: false
    }
  },
  activeToDate: {
    type: IKDataEntity.Types.Date,
    header: false,
    displayName: 'VoucherEndRange',
    formConfig: {
      required: false
    }
  },
  activeToTime: {
    type: IKDataEntity.Types.Time,
    header: false,
    displayName: 'VoucherEndTime',
    formConfig: {
      required: false
    }
  },
  updateTimestamp: {
    form: false,
    header: false,
    displayName: 'VoucherUpdateTimestamp'
  },
  active: {
    form: false,
    displayName: 'ActivateNow',
    type: IKDataEntity.Types.Boolean
  },
  oneTime: {
    displayName: 'OnlyOneTimeUsed',
    type: IKDataEntity.Types.Boolean,
    formConfig: {
      required: false
    }
  }
}

function initVoucher (item) {
  item.oneTime = !!item.oneTime
  item.minPrice = item.minPrice || 0
  item.maxPrice = item.maxPrice || 9999
  item.activeToDate = item.activeToDate || '9999-12-31'
  item.activeToTime = item.activeToTime || '23:59:59'
  item.activeFromDate = item.activeFromDate || '0000-01-01'
  item.activeFromTime = item.activeFromTime || '00:00:00'
}

const config = {
  load: async function () {
    const res = await getTakeawayVoucher()
    res.map(it => {
      it.activeTo = it.activeToDate + ' ' + it.activeToTime
      it.activeFrom = it.activeFromDate + ' ' + it.activeFromTime
      it.editMaxCount = it.maxCount
      it.oneTime = !!it?.oneTime
      return it
    })
    return res
  },
  rowClass (item) {
    return item.currentCount < 1 ? 'grey--text' : 'font-weight-black'
  },
  add: async function (item) {
    try {
      item.id = item.code
      item.active = 1
      item.maxCount = item.maxCount || 999
      item.currentCount = item.maxCount
      initVoucher(item)
      item.updateTimestamp = dayjs().format('YYYY-MM-DD HH:mm:ss')
      await addTakeawayVoucher(item)
    } catch (e) {
      console.log(e)
    }
  },
  edit: async function (item) {
    await deleteTakeawayVoucher(item.id)
    item.id = item.code
    if (parseInt(item.editMaxCount) !== parseInt(item.maxCount)) {
      item.maxCount = item.editMaxCount || 999
      item.currentCount = item.maxCount
    }
    initVoucher(item)
    item.updateTimestamp = dayjs().format('YYYY-MM-DD HH:mm:ss')
    await addTakeawayVoucher(item)
  },
  remove: async function (id) {
    await deleteTakeawayVoucher(id)
  }
}

export default IKDataEntity.ModelFactory(entity, config)
