<template>
  <div>
    <div class="d-flex align-center justify-center">
      <page-header class="py-4 pb-6">
        {{ $t('DynamicManage') }}
        <template #subtitle>
          {{ $t('ManageStoresUpdates') }}
        </template>
      </page-header>
      <v-spacer />
      <v-btn
        class="amber lighten-4"
        outlined
        @click="activityPostDialog = true"
      >
        {{ $t('PublishNewMessages') }}
      </v-btn>
    </div>
    <loading-provider
      :loading="loading"
    >
      <div>
        <v-card
          v-for="item in allActivityList"
          :key="item.id"
          class="pa-4 mb-4"
          outlined
          width="100%"
          @click="editActivity(item)"
        >
          <div class="d-flex">
            <div class="pa-2">
              <v-img
                :src="imgRoot + item.imagePath"
                height="96"
                width="96"
              />
            </div>

            <div
              class="text-body-2 pa-4"
              style="white-space: pre-line"
            >
              {{ item.content }}
            </div>
          </div>
          <div class="d-flex text-body-2">
            <div>
              <div>@{{ item.createdAt | beautifulTime }}</div>
            </div>
            <v-spacer />
            <div>
              <v-icon
                @click.stop="deleteActivity(item)"
              >
                mdi-delete
              </v-icon>
            </div>
          </div>
        </v-card>
      </div>
    </loading-provider>
    <v-dialog
      v-model="activityPostDialog"
      max-width="600px"
    >
      <v-card class="pa-4 ">
        <div class="d-flex align-center">
          {{ $t('Dynamic') }}
          <v-spacer />
          <v-btn
            icon
            @click="activityPostDialog = false"
          >
            <v-icon large>
              mdi-close
            </v-icon>
          </v-btn>
        </div>

        <div>
          <v-card
            :height="usePic?'100%':72"
            :width="usePic?'100%':72"
            class="my-4"
            color="grey lighten-4"
            elevation="0"
            @click="$refs.file.$refs.input.click()"
          >
            <v-icon
              class=" lighten-4 rounded-lg"
              style="position: absolute;right: 0;bottom: 0;top: 0;left: 0;margin: auto"
              x-large
            >
              mdi-plus
            </v-icon>
            <v-img
              :height="usePic?'100%':72"
              :src="usePic"
              :width="usePic?'100%':72"
              style="border-radius: 12px"
            />
            <v-file-input
              ref="file"
              accept="image/*"
              dense
              hide-details
              outlined
              prepend-icon=""
              style="height: 0;opacity: 0"
              @change="processFile"
            />
          </v-card>
          <div class="my-4">
            <v-textarea
              v-model="newActivityContent"
              :placeholder="$t('WriteNewMessages')"
              auto-grow
              hide-details
              outlined
            />
          </div>

          <v-btn
            :loading="btnLoading"
            block
            class="amber lighten-4 mb-4"
            outlined
            @click="createNewPost"
          >
            {{ $t('Publish') }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {
  createActivityPostList,
  deleteActivityPostList,
  getActivityPostList,
  updateActivityPostList
} from '@/model/dataLayer/api/takeaway/TakeawayManager'
import PageHeader from '@/views/widget/typography/PageHeader'
import { getRootUrl } from '@/model/global/GlobalSettings'
import LoadingProvider from '@/views/widget/LoadingProvider'
import { showSuccessMessage } from '@/model/utils/utils'

export default {
  name: 'ActivityPost',
  components: {
    LoadingProvider,
    PageHeader
  },
  data () {
    return {
      btnLoading: false,
      loading: false,
      editId: -1,
      imgFile: null,
      usePic: null,
      newActivityContent: '',
      activityPostDialog: false,
      allActivityList: []
    }
  },
  computed: {
    imgRoot () {
      return getRootUrl() + '/Resource/'
    }
  },
  watch: {
    activityPostDialog (val) {
      if (!val) {
        this.imgFile = null
        this.usePic = null
        this.newActivityContent = ''
        this.editId = -1
      }
    }
  },
  async mounted () {
    await this.reload()
  },
  methods: {
    async reload () {
      this.loading = true
      this.allActivityList = await getActivityPostList()
      this.allActivityList.sort(this.dateCompare('createdAt'))
      this.loading = false
    },
    dateCompare (dateKey) {
      return function (a, b) {
        return Date.parse(b[dateKey]) - Date.parse(a[dateKey])
      }
    },
    async deleteActivity (item) {
      await deleteActivityPostList(item.id)
      showSuccessMessage('delete_success')
      await this.reload()
    },
    async editActivity (item) {
      this.editId = item.id
      this.usePic = this.imgRoot + item.imagePath
      this.newActivityContent = item.content
      this.activityPostDialog = true
    },
    async createNewPost () {
      this.btnLoading = true
      if (this.editId === -1) {
        await createActivityPostList(this.newActivityContent, this.imgFile)
      } else {
        await updateActivityPostList(this.editId, this.newActivityContent, this.imgFile)
      }
      this.btnLoading = false
      this.activityPostDialog = false
      showSuccessMessage('SuccessfullyPosted')
      await this.reload()
    },
    async processFile (event) {
      this.usePic = URL.createObjectURL(event)
      this.imgFile = event
    }
  }
}
</script>

<style scoped>

</style>
