<template>
  <div>
    <page-header class="py-4 pb-6">
      {{ $t('PickupAnnouncement') }}
      <template #subtitle>
        {{ $t('PickupAnnouncementSubtitle') }}
      </template>
    </page-header>
    <loading-provider :loading="loading">
      <div class="text-body-1 mt-2">
        {{ $t('title') }}
      </div>
      <v-text-field
        v-model="announcementHead"
        :placeholder="$t('Welcome')"
        outlined
      />
      <div class="text-body-1 mt-2">
        {{ $t('ingredientIncludes') }}
      </div>
      <v-textarea
        v-model="announcementBody"
        :placeholder="$t('Welcome')"
        dense
        outlined
      />
      <div>
        <v-btn
          :loading="btnLoading"
          class="amber lighten-4"
          outlined
          width="100%"
          @click="save"
        >
          {{ $t('save') }}
        </v-btn>
      </div>
    </loading-provider>
  </div>
</template>

<script>
import { getNiceRestaurantInfo, updateRestaurantInfo } from '@/model/dataLayer/service/otherService'
import PageHeader from '@/views/widget/typography/PageHeader'
import LoadingProvider from '@/views/widget/LoadingProvider'
import { showSuccessMessage } from '@/model/utils/utils'
import { syncData } from '@/model/dataLayer/api/takeaway/TakeawayManager'

export default {
  components: {
    LoadingProvider,
    PageHeader
  },
  data () {
    return {
      announcementBody: '',
      btnLoading: false,
      loading: false,
      restaurantInfo: [],
      announcementHead: ''
    }
  },
  async mounted () {
    await this.reload()
  },
  methods: {
    async reload () {
      this.loading = true
      this.restaurantInfo = await getNiceRestaurantInfo()
      this.announcementHead = this.restaurantInfo.buffetAnnouncementHead
      this.announcementBody = this.restaurantInfo.buffetAnnouncementBody
      this.loading = false
    },
    async save () {
      this.btnLoading = true
      this.restaurantInfo.buffetAnnouncementHead = this.announcementHead
      this.restaurantInfo.buffetAnnouncementBody = this.announcementBody
      await updateRestaurantInfo(this.restaurantInfo)
      this.btnLoading = false
      showSuccessMessage('SuccessfullyChanged')
      try {
        await syncData()
      } catch (e) {
        console.log(e, 'error')
      }
      await this.reload()
    }
  }
}
</script>

<style scoped>

</style>
